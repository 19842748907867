import { ContainerMaxWidth } from '@hypersay/ui';
import React from 'react';

import SEO from '../../components/seo';
import T from '../../components/T';
import LayoutBlog from '../../layouts/blog';

// import { withPrefix } from 'gatsby';
import * as styles from './guide.module.scss';

const Business = () => (
  <LayoutBlog>
    <SEO
      title="Hypersay Blog - Resources on Interactive Presentations"
      description="Read articles on how to build interactive presentations and how to use Hypersay effectively."
      keywords={[
        'blog',
        'knowledge',
        'learn',
        'interactive presentations',
        'business',
        'corporate',
        'training',
        'townhall meetings',
        'powerpoint',
        'google slides',
      ]}
    />
    <ContainerMaxWidth className={styles.container} maxWidth="1400px">
      <T tagName="h1" id="HyperSpeech" />
    </ContainerMaxWidth>
  </LayoutBlog>
);

export default Business;
